import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles'
import { bindActionCreators } from 'redux'
import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/icons/Menu'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useApolloClient } from '@apollo/react-hooks'
import links, { externalContentLinks } from '@api/links'
import Box from '@base/Box'
import Logo from '@base/Logo'
import Link, { Props as LinkProps } from '@base/Link'
import Button from '@base/Button'
import SideNavigation from '@layouts/SideNavigation'
import { snackbarOperations } from '@src/store/modules/Snackbar'
import { userOperations, userSelectors, Role } from '@src/store/modules/User'
import { hideScrollbar } from '@lib/theme/mixins'
import { useTranslation } from '@src/i18n'
import UserMenu from './navBar/UserMenu'
import NavButton from './navBar/NavButton'
import Popover from './navBar/Popover'
import LocalSettings from '@layouts/LocalSettings'

const useStyles = makeStyles(({ palette, spacing: themeSpacing }: Theme) => ({
  button: {
    fontSize: '1rem',
    fontWeight: 'normal',
  },
  emphasizedButton: {
    color: palette.grey[900],
    fontSize: '1rem',
    fontWeight: 'normal',
  },
  authButton: {
    paddingLeft: themeSpacing(3),
    paddingRight: themeSpacing(3),
  },
  group: {
    alignItems: 'center',
    display: 'flexbox',
  },
  new: {
    marginLeft: 5,
  },
  scrollbar: hideScrollbar(),
}))

type ReduxStateProps = ReturnType<typeof mapStateToProps>

type ReduxDispatchProps = ReturnType<typeof mapDispatchToProps>

type Props = ReduxStateProps & ReduxDispatchProps

type ButtonProps = {
  button?: MuiButtonProps
  emphasized?: boolean
  link?: LinkProps
  title: React.ReactNode
  testId?: string
}

const spacing = { xs: 1, md: 1 }

const makeInvertedSpacing = normalSpacing => {
  const invertedSpacing = {}
  Object.keys(normalSpacing).forEach(breakpoint => {
    invertedSpacing[breakpoint] = -normalSpacing[breakpoint]
  })
  return invertedSpacing
}

const NavBar = ({ logoutUser, openSnackbar, isLoggedIn, userToken }: Props) => {
  const client = useApolloClient()
  const classes = useStyles({})
  const theme: Theme = useTheme()
  const isStudent = userToken ? userToken.roles.includes(Role.Student) : false
  const isTutor = userToken ? userToken.roles.includes(Role.Tutor) : false

  const desktopNavBreakpoint = 'md'
  const shrinkDesktopNav = !useMediaQuery(theme.breakpoints.up('lg'))
  const isMobileNavAllowed = !useMediaQuery(theme.breakpoints.up(desktopNavBreakpoint))
  const [isMobileNavOpen, setMobileNavOpen] = useState(false)
  const { t } = useTranslation()

  const handleLogout = () => {
    logoutUser(client).catch(() => {
      openSnackbar(t('auth.logout.error'), 'error')
    })
  }

  const renderButton = ({ button, link, emphasized, title, testId }: ButtonProps) => (
    <NavButton
      data-testid={testId || 'navLink'}
      buttonProps={button}
      link={link}
      emphasized={emphasized}
      title={title}
    />
  )

  return (
    <>
      <Box
        alignItems='center'
        display='flex'
        justifyContent='space-between'
        width={{
          xs: `calc(100% + ${2 * spacing.xs * theme.spacing(1)}px)`,
          md: `calc(100% + ${2 * spacing.md * theme.spacing(1)}px)`,
        }}
        mx={makeInvertedSpacing(spacing)}
      >
        <Box alignItems='center' display='flex'>
          <Box display='flex' alignItems='center' flexGrow={{ md: 1 }} pr={{ xs: 1, md: 2 }} mx={spacing}>
            <Link href={links.home}>
              <Logo />
            </Link>
          </Box>
        </Box>
        {/* scrolling section - start */}
        <Box
          display={{ xs: 'none', [desktopNavBreakpoint]: 'flex' }}
          flexDirection='row-reverse'
          justifyContent='space-between'
          flexGrow={1}
          whiteSpace='nowrap'
          overflow='auto'
          className={classes.scrollbar}
        >
          <Box display='flex'>
            {renderButton({
              title: t('navigation.howItWorks'),
              link: { href: links.howItWorks },
              testId: 'howItWorksMenuLink',
            })}
            {!isTutor &&
              renderButton({
                title: t('navigation.findTutor'),
                link: { href: links.tutorSearch },
                testId: 'allTherapistsMenuLink',
              })}
            {!shrinkDesktopNav &&
              renderButton({
                title: t('navigation.pricing'),
                link: { href: links.pricing },
                testId: 'pricingMenuLink',
              })}
            {!shrinkDesktopNav &&
              renderButton({
                title: t('navigation.b2b'),
                link: externalContentLinks.toBusiness,
                testId: 'b2bMenuLink',
              })}
            {!shrinkDesktopNav &&
              renderButton({
                title: t('navigation.blog'),
                link: { href: links.blog },
                testId: 'blogMenuLink',
              })}
            <Popover
              parent={{ title: t('navigation.more.title'), testId: 'moreMenuLink' }}
              list={[
                {
                  title: t('navigation.pricing'),
                  link: { href: links.pricing },
                  hidden: !shrinkDesktopNav,
                  testId: 'pricingShrunkMenuLink',
                },
                {
                  title: t('navigation.b2b'),
                  link: externalContentLinks.toBusiness,
                  hidden: !shrinkDesktopNav,
                  testId: 'b2bShrunkMenuLink',
                },
                {
                  title: t('navigation.blog'),
                  link: { href: links.blog },
                  hidden: !shrinkDesktopNav,
                  testId: 'blogShrunkMenuLink',
                },
                {
                  title: t('navigation.team'),
                  link: externalContentLinks.team,
                  testId: 'teamMenuLink',
                },
                {
                  title: t('navigation.topics'),
                  link: externalContentLinks.topics,
                  testId: 'topicsMenuLink',
                },
                {
                  title: t('navigation.faq'),
                  link: externalContentLinks.faq,
                  testId: 'faqMenuLink',
                },
                {
                  title: t('navigation.podcast'),
                  link: externalContentLinks.podcast,
                  testId: 'podcastMenuLink',
                },
                {
                  title: t('navigation.giftVouchers'),
                  link: externalContentLinks.coupons,
                  testId: 'couponsMenuLink',
                },
                {
                  title: t('navigation.join'),
                  link: externalContentLinks.joinUs,
                  testId: 'joinMenuLink',
                },
                {
                  title: t('navigation.forMedia'),
                  link: externalContentLinks.forMedia,
                  testId: 'forMediaMenuLink',
                },
              ]}
            />
          </Box>
        </Box>
        {/* scrolling section - end */}
        <Box alignItems='center' display='flex' whiteSpace='nowrap'>
          {isLoggedIn && (
            <Box mx={spacing} data-testid='loggedInNav' display={{ xs: 'none', [desktopNavBreakpoint]: 'flex' }}>
              <UserMenu onLogout={handleLogout} isStudent={isStudent} isTutor={isTutor} />
            </Box>
          )}
          {!isLoggedIn && (
            <>
              <Box mx={spacing} display={{ xs: 'none', [desktopNavBreakpoint]: 'block' }}>
                <Link {...links.login}>
                  <Button data-testid='loginMenuLink' color='primary' size='medium' variant='outlined'>
                    {t('navigation.login')}
                  </Button>
                </Link>
              </Box>
              <Box mr={spacing} display={{ xs: 'none', [desktopNavBreakpoint]: 'block' }}>
                <Link {...links.signup}>
                  <Button
                    className={classes.authButton}
                    color='primary'
                    data-testid='signupButton'
                    size='medium'
                    variant='contained'
                  >
                    {t('navigation.signup')}
                  </Button>
                </Link>
              </Box>
            </>
          )}
          <Box display={{ xs: 'block', [desktopNavBreakpoint]: 'none' }} mr={-1}>
            <IconButton color='inherit' onClick={() => setMobileNavOpen(true)} aria-label='Menu'>
              <Menu />
            </IconButton>
          </Box>
          <LocalSettings display={{ xs: 'none', md: 'inline-flex' }} />
        </Box>
      </Box>
      <SideNavigation
        isOpen={isMobileNavAllowed && isMobileNavOpen}
        onOpen={() => setMobileNavOpen(true)}
        onClose={() => setMobileNavOpen(false)}
      />
    </>
  )
}

const mapStateToProps = state => {
  const userState = userSelectors.getSelf(state)

  return {
    isLoggedIn: userSelectors.isLoggedIn(userState),
    userToken: userSelectors.getTokenPayload(userState),
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logoutUser: userOperations.logout,
      openSnackbar: snackbarOperations.open,
    },
    dispatch,
  )

const NavBarMapped = connect(mapStateToProps, mapDispatchToProps)(NavBar)

export default NavBarMapped
