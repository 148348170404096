import React, { ReactNode } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles'
import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button'

import Box from '@base/Box'
import Button from '@base/Button'
import Link, { Props as LinkProps } from '@base/Link'

const useStyles = makeStyles(({ palette }: Theme) => ({
  button: {
    fontSize: '1rem',
    paddingLeft: 10,
    paddingRight: 10,
  },
  emphasizedButton: {
    color: palette.grey[900],
    fontSize: '1rem',
    paddingLeft: 10,
    paddingRight: 10,
  },
}))

type Props = {
  buttonProps?: MuiButtonProps
  emphasized?: boolean
  link?: LinkProps
  title: ReactNode
}

const NavButton = ({ buttonProps, link, emphasized, title }: Props) => {
  const classes = useStyles({})

  const buttonEl = (
    <Button
      className={emphasized ? classes.emphasizedButton : classes.button}
      color='inherit'
      size='small'
      {...buttonProps}
    >
      {title}
    </Button>
  )

  return (
    <Box display='block' mx={{ xs: 0, md: 0 }}>
      {link ? <Link {...link}>{buttonEl}</Link> : buttonEl}
    </Box>
  )
}

export default NavButton
