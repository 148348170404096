import { withTheme, WithTheme } from '@material-ui/core/styles'
import React, { ReactNode } from 'react'

import BoxContainer, { Props as BoxContainerProps } from '@base/BoxContainer'

export type Props = BoxContainerProps & {
  children: ReactNode
}

const HeaderContainer = ({ children, theme, ...props }: WithTheme & Props) => {
  return (
    <BoxContainer borderBottom={`1px solid ${theme.palette.grey[300]}`} py={2} {...props}>
      {children}
    </BoxContainer>
  )
}

export default withTheme(HeaderContainer)
