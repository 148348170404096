import React, { ReactNode } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles'
import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'

import Box from '@base/Box'
import Button from '@base/Button'
import Link, { Props as LinkProps } from '@base/Link'

const useStyles = makeStyles(({ palette }: Theme) => ({
  button: {
    fontSize: '1rem',
    paddingLeft: 10,
    paddingRight: 0,
  },
  emphasizedButton: {
    color: palette.grey[900],
    fontSize: '1rem',
    paddingLeft: 10,
    paddingRight: 0,
  },
}))

type Props = {
  buttonProps?: MuiButtonProps
  emphasized?: boolean
  link?: LinkProps
  title: ReactNode
  open: boolean
  testId?: string
}

const NavButtonMore = ({ buttonProps, link, emphasized, title, open, testId }: Props) => {
  const classes = useStyles({})

  const buttonEl = (
    <Button
      className={emphasized ? classes.emphasizedButton : classes.button}
      color='inherit'
      size='small'
      {...buttonProps}
      data-testid={testId || 'navMoreButton'}
    >
      {title} {open ? <ExpandLess /> : <ExpandMore />}
    </Button>
  )

  return (
    <Box display='block' mx={{ xs: 1, md: 1 }}>
      {link ? <Link {...link}>{buttonEl}</Link> : buttonEl}
    </Box>
  )
}

export default NavButtonMore
