import React, { ReactNode } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import MuiListItem from '@material-ui/core/ListItem'
import Typography from '@base/Typography'
import ListItem, { ListItemProps } from '@base/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

export type NestedListProps = {
  parent: {
    title: ReactNode
  }
  list: ListItemProps[]
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nested: {
      paddingLeft: theme.spacing(2),
    },
  }),
)

const NestedList = ({ parent, list }: NestedListProps) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <>
      <MuiListItem button onClick={handleClick}>
        <ListItemText>
          <Typography variant='body2'>{parent.title}</Typography>
        </ListItemText>
        {open ? <ExpandLess /> : <ExpandMore />}
      </MuiListItem>
      <Collapse in={open} timeout='auto' unmountOnExit className={classes.nested}>
        {list.map((listItemProps: ListItemProps, index: number) => (
          <ListItem key={index} {...listItemProps} />
        ))}
      </Collapse>
    </>
  )
}

export default NestedList
