import { ReactNode } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import MuiPopover from '@material-ui/core/Popover'
import React, { useState } from 'react'
import ListItem, { ListItemProps as BaseListItemProps } from '@base/ListItem'
import PopoverContent from '@base/PopoverContent'
import NavButtonMore from './NavButtonMore'
import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button'
import { Props as LinkProps } from '@base/Link'

type ListItemProps = BaseListItemProps & { hidden?: boolean }

type Props = {
  parent: {
    buttonProps?: MuiButtonProps
    emphasized?: boolean
    link?: LinkProps
    title: ReactNode
    testId?: string
  }
  list: ListItemProps[]
}

const useStyles = makeStyles(({ spacing }: Theme) => ({
  popover: {
    marginTop: spacing(0.5),
  },
}))

const Popover = ({ parent, list }: Props) => {
  const classes = useStyles({})
  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)

  const handleClick = ev => {
    setAnchorEl(ev.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <NavButtonMore {...parent} buttonProps={{ ...parent.buttonProps, onClick: handleClick }} open={open} />
      <MuiPopover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        className={classes.popover}
        elevation={0}
        onClose={handleClose}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <PopoverContent>
          <List>
            {list
              .filter(element => !element.hidden)
              .map((element: ListItemProps, index: number) => (
                <ListItem key={index} {...element} onClick={handleClose} />
              ))}
          </List>
        </PopoverContent>
      </MuiPopover>
    </>
  )
}

export default Popover
