import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Close from '@material-ui/icons/Close'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import { withRouter } from 'next/router'
import { WithRouterProps } from 'next/dist/client/with-router'
import React from 'react'
import { useApolloClient } from '@apollo/react-hooks'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import links, { externalContentLinks } from '@api/links'
import Box from '@base/Box'
import ListItem, { ListItemProps } from '@base/ListItem'
import NestedList, { NestedListProps } from '@base/NestedList'
import Button from '@base/Button'
import LocalSettings from '@layouts/LocalSettings'
import { snackbarOperations } from '@src/store/modules/Snackbar'
import { userOperations, userSelectors } from '@src/store/modules/User'
import { useTranslation } from '@src/i18n'
import getLogger from '@src/Logger'
import Logo from '@base/Logo'
import Link from '@base/Link'

const useStyles = makeStyles(({ palette, spacing }: Theme) => ({
  paper: {
    background: palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 230,
    overflow: 'auto',
    width: '100vw',
    paddingBottom: 24,
  },
  closeBtn: {
    fontSize: '0.875rem',
    fontWeight: 600,
    marginTop: -spacing(0.5),
  },
  listTop: {
    marginTop: 0,
  },
  top: {
    flex: '1 1 auto',
  },
  '@global': {
    '.intercom-launcher-frame': {
      zIndex: 1050,
    },
  },
}))

type ReduxStateProps = ReturnType<typeof mapStateToProps>

type ReduxDispatchProps = ReturnType<typeof mapDispatchToProps>

type OwnProps = {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
}

type Props = WithRouterProps & ReduxStateProps & ReduxDispatchProps & OwnProps

const SideNavigation = ({
  isLoggedIn,
  isOpen,
  isStudent,
  logoutUser,
  openSnackbar,
  onClose,
  onOpen,
  router,
}: Props) => {
  const client = useApolloClient()
  const classes = useStyles({})
  const { t } = useTranslation()
  const theme: Theme = useTheme()
  const desktopNavBreakpoint = 'md'
  const isDesktop = useMediaQuery(theme.breakpoints.up(desktopNavBreakpoint))

  const handleLogout = () => {
    logoutUser(client).catch(() => {
      openSnackbar(t('auth.logout.error'), 'error')
    })
  }

  const renderListItem = (listItemProps: ListItemProps) => {
    if (router === null) {
      getLogger().info('router is null, SideNavigation')
    }

    const selected = listItemProps.link && router.pathname === listItemProps.link.href
    // All links have close bahavior on click
    const handleClick = () => {
      listItemProps.onClick && listItemProps.onClick()
      onClose()
    }

    return <ListItem {...listItemProps} onClick={handleClick} selected={selected} />
  }

  const renderNestedList = (nestedListProps: NestedListProps) => {
    if (router === null) {
      getLogger().info('router is null, SideNavigation')
    }

    const listProps = nestedListProps.list.map(listItem => {
      const selected = listItem.link && router.pathname === listItem.link.href
      // All links have close bahavior on click
      const onClick = () => {
        listItem.onClick && listItem.onClick()
        onClose()
      }

      return {
        ...listItem,
        selected,
        onClick,
      }
    })

    const props: NestedListProps = { ...nestedListProps, list: listProps }

    return <NestedList {...props} />
  }

  const howItWorks = renderListItem({
    title: t('navigation.howItWorks'),
    link: { href: links.howItWorks },
    testId: 'howItWorksSideMenuLink',
  })
  const tutors = renderListItem({
    title: t('navigation.findTutor'),
    link: { href: links.tutorSearch },
    testId: 'findTherapistSideMenuLink',
  })
  const pricing = renderListItem({
    title: t('navigation.pricing'),
    link: { href: links.pricing },
    testId: 'pricingSideMenuLink',
  })
  const b2b = renderListItem({
    title: t('navigation.b2b'),
    link: externalContentLinks.toBusiness,
    testId: 'b2bSideMenuLink',
  })
  const blog = renderListItem({
    title: t('navigation.blog'),
    link: { href: links.blog },
    testId: 'blogSideMenuLink',
  })
  const more = renderNestedList({
    parent: { title: t('navigation.more.title') },
    list: [
      {
        title: t('navigation.team'),
        link: externalContentLinks.team,
        testId: 'teamSideMenuLink',
      },
      {
        title: t('navigation.topics'),
        link: externalContentLinks.topics,
        testId: 'topicsSideMenuLink',
      },
      {
        title: t('navigation.faq'),
        link: externalContentLinks.faq,
        testId: 'faqSideMenuLink',
      },
      {
        title: t('navigation.podcast'),
        link: externalContentLinks.podcast,
        testId: 'podcastSideMenuLink',
      },
      {
        title: t('navigation.giftVouchers'),
        link: externalContentLinks.coupons,
        testId: 'couponsSideMenuLink',
      },
      {
        title: t('navigation.join'),
        link: externalContentLinks.joinUs,
        testId: 'joinSideMenuLink',
      },
      {
        title: t('navigation.forMedia'),
        link: externalContentLinks.forMedia,
        testId: 'forMediaSideMenuLink',
      },
    ],
  })

  const login = renderListItem({ title: t('navigation.login'), emphasized: true, link: links.login })
  const logout = renderListItem({ title: t('navigation.logout'), button: true, onClick: handleLogout })
  const dashboard = renderListItem({ title: t('navigation.dashboard'), link: { href: links.dashboard } })
  const updateProfile = renderListItem({ title: t('navigation.updateProfile'), link: { href: links.updateProfile } })
  const therapistPayouts = renderListItem({ title: t('navigation.payouts'), link: { href: links.payouts } })

  const notificationSettings = renderListItem({
    title: t('navigation.notificationSettings'),
    link: { href: links.notifications },
  })

  return (
    <SwipeableDrawer
      anchor='right'
      classes={{
        paper: classes.paper,
      }}
      open={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    >
      <div>
        <Button className={classes.closeBtn} onClick={onClose} size='small'>
          <Close fontSize='small' />
        </Button>
      </div>
      <Box
        color={theme.palette.customCol.silver}
        alignItems='center'
        display='flex'
        justifyContent='space-between'
        px={3}
        pt={1}
      >
        <Box mr={1}>
          <Link href={links.home}>
            <Logo />
          </Link>
        </Box>
      </Box>
      <div className={classes.top}>
        {!isLoggedIn && (
          <>
            <List className={classes.listTop} component='nav'>
              {howItWorks}
              {tutors}
              {pricing}
              {b2b}
              {blog}
              {more}
            </List>
            <Divider />
            <List component='nav'>{login}</List>
          </>
        )}
        {isLoggedIn && !isDesktop && (
          <List className={classes.listTop} component='nav'>
            {dashboard}
            {isStudent && updateProfile}
            {isStudent && notificationSettings}
            {!isStudent && therapistPayouts}
            {logout}
          </List>
        )}
        {/* Move LocalSettings between desktop (NavBar component) and mobile menu when there is not enough space */}
        <LocalSettings display={{ xs: 'inline-flex', [desktopNavBreakpoint]: 'none' }} maxWidth={140} pl={3} />
      </div>
      {isLoggedIn && (
        <>
          <Box mt={3}>
            <Divider />
          </Box>
          <List component='nav'>
            {howItWorks}
            {tutors}
            {pricing}
            {b2b}
            {blog}
            {more}
          </List>
        </>
      )}
    </SwipeableDrawer>
  )
}

const mapStateToProps = state => {
  const userState = userSelectors.getSelf(state)

  return {
    isLoggedIn: userSelectors.isLoggedIn(userState),
    isStudent: userSelectors.isStudent(userState),
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logoutUser: userOperations.logout,
      openSnackbar: snackbarOperations.open,
    },
    dispatch,
  )

const SideNavigationMapped = connect(mapStateToProps, mapDispatchToProps)(SideNavigation)

export default withRouter(SideNavigationMapped)
