import { CreateCSSProperties } from '@material-ui/core/styles/withStyles'

export const hideScrollbar = (): CreateCSSProperties => ({
  scrollbarWidth: 'none', // ff
  '&::-webkit-scrollbar': {
    display: 'none', // chrome
  },
  ' -ms-overflow-style': 'none', // edge
  '-webkit-overflow-scrolling': 'touch', // ios
})
