import cx from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import List from '@material-ui/core/List'
import Popover from '@material-ui/core/Popover'
import React, { useState } from 'react'
import links from '@api/links'
import ListItem from '@base/ListItem'
import PopoverContent from '@base/PopoverContent'
import Button from '@base/Button'
import { useTranslation } from '@src/i18n'

const useStyles = makeStyles(({ palette, spacing }: Theme) => ({
  button: {
    color: palette.grey[900],
    fontSize: '1rem',
    fontWeight: 600,
  },
  popover: {
    marginTop: spacing(0.5),
  },
}))

type UserMenuProps = {
  className?: string
  onLogout: () => void
  isStudent: boolean
  isTutor: boolean
}

const UserMenu = ({ className, onLogout, isStudent, isTutor }: UserMenuProps) => {
  const classes = useStyles({})
  const [anchorEl, setAnchorEl] = useState(null)
  const { t } = useTranslation()

  const open = Boolean(anchorEl)

  const handleClick = ev => {
    setAnchorEl(ev.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogoutClick = () => {
    handleClose()
    onLogout()
  }

  return (
    <>
      <Button
        className={cx(classes.button, className)}
        color='primary'
        onClick={handleClick}
        size='medium'
        variant='contained'
      >
        {t('navigation.profile')} {open ? <ExpandLess /> : <ExpandMore />}
      </Button>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        className={classes.popover}
        elevation={0}
        onClose={handleClose}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <PopoverContent>
          <List>
            <ListItem title={t('navigation.dashboard')} link={{ href: links.dashboard }} onClick={handleClose} />
            {isStudent && (
              <ListItem
                data-testid='updateProfileMenuLink'
                title={t('navigation.updateProfile')}
                link={{ href: links.updateProfile }}
                onClick={handleClose}
              />
            )}
            {isTutor && (
              <>
                <ListItem
                  data-testid='payoutMenuLink'
                  title={t('navigation.payouts')}
                  link={{ href: links.payouts }}
                  onClick={handleClose}
                />
              </>
            )}
            {isStudent && (
              <>
                <ListItem
                  title={t('navigation.notificationSettings')}
                  data-testid='notificationsMenuLink'
                  link={{ href: links.notifications }}
                  onClick={handleClose}
                />
              </>
            )}
            <ListItem button data-testid='logoutMenuLink' title={t('navigation.logout')} onClick={handleLogoutClick} />
          </List>
        </PopoverContent>
      </Popover>
    </>
  )
}

export default UserMenu
